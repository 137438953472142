<template>
  <div class="profile-wrap">
    <Row :gutter="24">
      <Col span="6">
        <div class="profile-left">
          <div class="info-item">
            <div class="left">
              <div class="name">{{profile.name}}</div>
              <div class="part" v-if="profile.department">{{profile.department}}-{{profile.position}}</div>
            </div>
          </div>
          <div class="info-item">
            <div class="left">
              <div class="label">密码</div>
            </div>
             <div class="right" @click="isEditor('2')">
              <i class="iconfont iconbianjibeifen"></i>
            </div>
          </div>
        </div>
      </Col>
      <Col span="18">
        <div class="profile-right">
          <div class="feed">
            <div class="label">您的建议</div>
            <div class="input">
              <Input v-model="feedback" maxlength="500" show-word-limit type="textarea" :rows="8" placeholder="请输入您的建议、需求、反馈等" />
            </div>
          </div>
          <div class="edit">
              <Button type="default" style="margin-right: 8px;color: #6B8299" @click="isClear">清空</Button>
              <Button type="primary" @click="isSubmit">提交</Button>
          </div>
        </div>
      </Col>
    </Row>
    <Modal
      v-model="modalShow"
      width="1016"
      :title="title"
      class-name="vertical-center-modal"
      :mask-closable="false"
      :closable="false"
    >
      <div class="card-wrap" style="padding: 36px 120px 36px 96px">
        <template v-if="editor === '0'">
          <FormItem :form="basicForm.name" />
          <FormItem :form="basicForm.company" @change="isChange" @company="isCompanyClick"/>
          <template v-if="part==='hospital'">
            <FormItem :form="basicForm.office" type="select" />
            <FormItem :form="basicForm.position" type="select" />
          </template>
          <template v-if="part==='unit'">
            <FormItem :form="basicForm.department" type="select" />
            <FormItem :form="basicForm.part" type="select" />
          </template>
          <FormItem :form="basicForm.mail" />
        </template>
        <template v-if="editor === '1'">
          <FormItem :form="mobileForm.initMobile" @blur="validateInitMobile"/>
          <FormItem :form="mobileForm.newMobile" @blur="validateNewMobile"/>
          <FormItem :form="mobileForm.verify"
            :show="isShowCode"
            :count="countDown"
            :time="total"
            :timer="timer"
            @code="isVerifyCode"
            @blur="validateCode"
          />
        </template>
        <template v-if="editor === '2'">
          <FormItem :form="passwordForm.initPassword" @blur="validateWord" />
          <FormItem :form="passwordForm.password" @blur="validateNewWord" />
          <FormItem :form="passwordForm.ensure" @blur="validateEnsure" />
        </template>
      </div>
      <div slot="footer" style="margin-right: 120px;padding-bottom: 48px">
        <Button type="text" @click="handleCancel">取消</Button>
        <Button type="primary" @click="handleOk">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
//
import {
  fetchVerifyCode,
  fetchInstitute,
  fetchPersonalInfo,
  fetchPostFeedBack,
  fetchPersonalBasicInfo,
  fetchPersonalMobile,
  fetchPersonalPassword
} from '@/api'
import { Row, Col, Input, Button, Modal, Message } from 'view-design'
import { titleMap, form, basicForm, mobileForm, passwordForm } from './config'
import FormItem from '../login/components/FormItem'
import cloneDeep from 'lodash/cloneDeep'
import { limitInputValue } from '@/utils/tools'

export default {
  name: 'Profile',
  data () {
    return {
      profile: {},
      modalShow: false,
      feedback: '',
      editor: '0',
      title: '个人资料修改',
      part: '',
      form: cloneDeep(form),
      basicForm: cloneDeep(basicForm),
      mobileForm: cloneDeep(mobileForm),
      passwordForm: cloneDeep(passwordForm),
      // 获取验证码相关
      isShowCode: false,
      countDown: 0,
      total: 60,
      timer: null
    }
  },
  components: {
    Row,
    Col,
    Input,
    Button,
    Modal,
    FormItem
  },

  created() {
    this.fetchOriginPersonalInfo()
  },

  computed: {
    companyType({ profile }) {
      if (profile.company_name.indexOf('医院') !== -1) {
        this.part = 'hospital'
        return 'hospital'
      } else if (profile.company_name.indexOf('公司') !== -1) {
        this.part = 'unit'
        return 'unit'
      } else {
        this.part = ''
        return ''
      }
    }
  },

  methods: {
    async fetchOriginPutBasicInfo(option) {
      await fetchPersonalBasicInfo(option)
      this.modalShow = false
      this.fetchOriginPersonalInfo()
    },

    async fetchOriginPutMobile(option) {
      const res = await fetchPersonalMobile(option)
      if (res.code === 200) {
        Message.success({
          content: '信息更新成功！',
          duration: 2
        })
        this.modalShow = false
        this.fetchOriginPersonalInfo()
      } else {
        Message.error({
          content: res.message,
          duration: 2
        })
      }
    },
    async fetchOriginPutPassword(option) {
      const res = await fetchPersonalPassword(option)
      if (res.code === 200) {
        Message.success({
          content: '信息更新成功！',
          duration: 2
        })
        this.modalShow = false
        this.fetchOriginPersonalInfo()
      } else {
        Message.error({
          content: res.message,
          duration: 2
        })
      }
    },
    async fetchOriginPutFeedBack() {
      const res = await fetchPostFeedBack({
        content: this.feedback
      })
      if (res.code === 200) {
        Message.success({
          content: '提交成功！',
          duration: 2
        })
        this.feedback = ''
      } else {
        Message.error({
          content: res.message,
          duration: 2
        })
      }
    },
    async fetchOriginPersonalInfo() {
      const res = await fetchPersonalInfo(localStorage.getItem('userID'))
      this.profile = res.data
    },
    async isChange(val) {
      if (!val.trim()) {
        this.basicForm.company.list = []
        this.part = ''
      } else {
        const res = await fetchInstitute({ keyword: val }, false)
        this.basicForm.company.list = res.data
        if (!res.length) {
          this.part = ''
        }
      }
    },
    async getVerifyCode(option) {
      const res = await fetchVerifyCode(option)
      if (res.message === 'success') {
        Message.success({
          content: '验证码已发送!',
          duration: 2
        })
        this.isShowCode = true
        if (!this.timer) {
          this.countDown = this.total
          this.timer = setInterval(() => {
            if (this.countDown > 0 && this.countDown <= this.total) {
              this.countDown--
            } else {
              this.isShowCode = false
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    isCompanyClick(item) {
      this.basicForm.company.value = item.company_name
      this.basicForm.company.province = item.province
      this.basicForm.company.list = []
      if (this.basicForm.company.value.indexOf('医院') !== -1) {
        this.part = 'hospital'
      } else if (this.basicForm.company.value.indexOf('公司') !== -1) {
        this.part = 'unit'
      } else {
        this.part = ''
      }
    },
    commonMethods(target, form, key) {
      const { reg, value } = target
      // const val = value.trim()
      if (!value) {
        this[form][key].show = false
      } else if (!reg.test(value)) {
        this[form][key].show = true
      } else {
        this[form][key].show = false
      }
    },
    validateNewWord() {
      this.commonMethods(this.passwordForm.password, 'passwordForm', 'password')
    },
    validateWord() {
      this.commonMethods(this.passwordForm.initPassword, 'passwordForm', 'initPassword')
    },
    validateEnsure() {
      this.commonMethods(this.passwordForm.ensure, 'passwordForm', 'ensure')
    },
    validateInitMobile() {
      this.commonMethods(this.mobileForm.initMobile, 'mobileForm', 'initMobile')
    },
    validateNewMobile() {
      this.commonMethods(this.mobileForm.newMobile, 'mobileForm', 'newMobile')
    },
    validateCode() {
      this.commonMethods(this.mobileForm.verify, 'mobileForm', 'verify')
    },
    isVerifyCode() {
      if ((this.mobileForm.newMobile.value && !this.mobileForm.newMobile.show) && (this.mobileForm.initMobile.value && !this.mobileForm.initMobile.show)) {
        if (this.mobileForm.newMobile.value === this.mobileForm.initMobile.value) {
          Message.warning({
            content: '两次手机号码一致！',
            duration: 2
          })
        } else {
          this.getVerifyCode({ mobile: this.mobileForm.newMobile.value })
        }
      } else {
        Message.error({
          content: '请填写正确的手机号码！',
          duration: 2
        })
      }
    },
    isClear() {
      this.feedback = ''
    },
    isSubmit() {
      if (this.feedback.trim()) {
        if (limitInputValue(this.feedback.trim()) < 5) {
          Message.warning({
            content: '请至少输入5个字符！',
            duration: 2
          })
        } else {
          this.fetchOriginPutFeedBack()
        }
      } else {
        Message.warning({
          content: '请输入相应内容后提交！',
          duration: 2
        })
      }
    },
    isEditor(key) {
      switch (key) {
        case '0':
          this.basicForm.name.value = this.profile.name
          this.basicForm.company.value = this.profile.company_name
          this.basicForm.mail.value = this.profile.email
          if (this.companyType === 'hospital') {
            this.basicForm.office.value = this.profile.department
            this.basicForm.position.value = this.profile.position
          } else if (this.companyType === 'unit') {
            this.basicForm.department.value = this.profile.department
            this.basicForm.part.value = this.profile.position
          }
          break

        default:
          break
      }
      this.editor = key
      this.title = titleMap[key]
      this.modalShow = true
    },
    handleCancel() {
      this.part = ''
      this.modalShow = false
      this.basicForm = cloneDeep(basicForm)
      this.mobileForm = cloneDeep(mobileForm)
      this.passwordForm = cloneDeep(passwordForm)
    },
    handleOk() {
      let labels = []
      // const validates = []

      switch (this.editor) {
        // 修改基础信息
        case '0':
          Object.keys(this.basicForm).forEach(key => {
            const item = this.basicForm[key]
            if (item.required && !item.value) {
              labels.push(item.label)
            }
          })
          if (this.part === 'hospital') {
            labels = labels.filter(v => v !== '部门' && v !== '职位')
          } else if (this.part === 'unit') {
            labels = labels.filter(v => v !== '科室' && v !== '职称')
          } else {
            labels = labels.filter(v => v !== '部门' && v !== '职位' && v !== '科室' && v !== '职称')
          }
          if (labels.length) {
            Message.warning({
              content: `${labels[0]}不能为空！`,
              duration: 2
            })
          } else {
            const { name, company } = this.basicForm
            if (limitInputValue(name.value) > 100) {
              Message.warning({
                content: '姓名长度为1-100！',
                duration: 2
              })
            } else if (limitInputValue(company.value) > 100) {
              Message.warning({
                content: '单位长度为1-100！',
                duration: 2
              })
            } else {
              // this.modalShow = false
              const option = {
                name: this.basicForm.name.value,
                province: this.basicForm.company.province || undefined,
                company_name: this.basicForm.company.value,
                company_type: this.part === 'hospital'
                  ? '1'
                  : this.part === 'unit'
                    ? '2'
                    : '3',
                department: this.part === 'hospital'
                  ? this.basicForm.office.value
                  : this.part === 'unit'
                    ? this.basicForm.department.value
                    : undefined,
                position: this.part === 'hospital'
                  ? this.basicForm.position.value
                  : this.part === 'unit'
                    ? this.basicForm.part.value
                    : undefined,
                email: this.basicForm.mail.value
              }
              this.fetchOriginPutBasicInfo(option)
              this.basicForm = cloneDeep(basicForm)
            }
          }
          break
        case '1':
          if (!this.mobileForm.initMobile.value.trim() || this.mobileForm.initMobile.show || !this.mobileForm.newMobile.value.trim() || this.mobileForm.newMobile.show || !this.mobileForm.verify.value.trim() || this.mobileForm.verify.show) {
            Message.warning({
              content: '*为必填项，请完善！',
              duration: 2
            })
          } else if (this.mobileForm.newMobile.value === this.mobileForm.initMobile.value) {
            Message.warning({
              content: '两次手机号码一致！',
              duration: 2
            })
          } else {
            const option = {
              old_mobile: this.mobileForm.initMobile.value,
              new_mobile: this.mobileForm.newMobile.value,
              captcha: this.mobileForm.verify.value
            }
            this.fetchOriginPutMobile(option)
            this.mobileForm = cloneDeep(mobileForm)
          }
          break
        case '2':
          if (!this.passwordForm.initPassword.value.trim() || this.passwordForm.initPassword.show || !this.passwordForm.password.value.trim() || this.passwordForm.password.show || !this.passwordForm.ensure.value.trim() || this.passwordForm.ensure.show) {
            Message.warning({
              content: '*为必填项，请完善！',
              duration: 2
            })
          } else if (this.passwordForm.initPassword.value === this.passwordForm.password.value) {
            Message.warning({
              content: '原密码和新密码一致，请修改！',
              duration: 2
            })
          } else if (this.passwordForm.ensure.value !== this.passwordForm.password.value) {
            Message.warning({
              content: '新密码和确认密码不一致，请修改！',
              duration: 2
            })
          } else {
            const option = {
              old_mm: this.passwordForm.initPassword.value,
              new_mm: this.passwordForm.password.value,
              confirm_mm: this.passwordForm.ensure.value
            }
            this.fetchOriginPutPassword(option)
            this.passwordForm = cloneDeep(passwordForm)
          }
          break
        default:
          break
      }
    }
  }
}

</script>
<style lang='less'>
@import url('./index.less');
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    max-height: 620px;
    top: 0;
  }
  .ivu-modal-header-inner {
    font-weight: 700;
    font-size: 20px;
    color: #2D3937;
  }
  .ivu-modal-close {
    .ivu-icon-ios-close {
      font-weight: 700;
      font-size: 36px;
      color: #2D3937;
    }
  }
  .ivu-modal-footer{
    border-top: none;
    margin-top: -12px;
    padding-bottom: 16px;
  }
  .cancle{
    border: none;
  }
}
</style>
