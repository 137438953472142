import { getSelectList } from '@/utils/tools'

export const office = ['检验科', '病理科', '急诊心内科', '心胸外科', '呼吸科', '感染科', '妇科产科', '消化科', '儿科', '骨科', '肿瘤科', '内分泌科', '普外科', '肾内科', '生殖中心', '神经内科', '影像科', '老年科', '其它']

export const position = ['主任医师', '主任技师', '副主任医师', '副主任技师', '主治医师', '住院医师', '主管技师', '技师', '其它']

export const department = ['医学部', '市场部', '销售部', '研发部', '其他部门']

export const part = ['总监', '部门经理', '专员', '职员/助理', '其他']

export const company = ['医院1', '医院2', '公司1', '公司2']

export const basicForm = {
  name: {
    label: '姓名',
    value: '',
    required: true,
    placeholder: '请填写您的姓名'
  },
  company: {
    label: '单位',
    value: '',
    required: true,
    placeholder: '请填写您的工作单位',
    list: []
  },
  office: {
    label: '科室',
    value: '',
    required: true,
    placeholder: '请选择科室',
    list: getSelectList(office)
  },
  position: {
    label: '职称',
    value: '',
    required: true,
    placeholder: '请选择职称',
    list: getSelectList(position)
  },
  department: {
    label: '部门',
    value: '',
    required: true,
    placeholder: '请选择部门',
    list: getSelectList(department)
  },
  part: {
    label: '职位',
    value: '',
    required: true,
    placeholder: '请选择职位',
    list: getSelectList(part)
  },
  mail: {
    label: '邮箱',
    value: '',
    required: false,
    placeholder: '请输入您的邮箱'
  }
}

export const mobileForm = {
  initMobile: {
    label: '原手机号',
    value: '',
    required: true,
    placeholder: '请输入11位手机号',
    show: false,
    message: '请输入正确的11位手机号',
    reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  },
  newMobile: {
    label: '新手机号',
    value: '',
    required: true,
    placeholder: '请输入11位手机号',
    show: false,
    message: '请输入正确的11位手机号',
    reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  },
  verify: {
    label: '验证码',
    value: '',
    required: true,
    placeholder: '验证码',
    show: false,
    message: '请输入正确的6位验证码',
    reg: /^\d{6}$/
  }
}

export const passwordForm = {
  initPassword: {
    label: '原密码',
    value: '',
    required: true,
    placeholder: '请输入6-16位密码',
    show: false,
    reg: /^[a-z0-9]{6,16}$/,
    message: '密码应为6～16位字母、数字或符号'
  },
  password: {
    label: '新密码',
    value: '',
    required: true,
    placeholder: '请输入6-16位密码',
    show: false,
    reg: /^[a-z0-9]{6,16}$/,
    message: '密码应为6～16位字母、数字或符号'
  },
  ensure: {
    label: '确认密码',
    value: '',
    required: true,
    placeholder: '确认密码',
    show: false,
    reg: /^[a-z0-9]{6,16}$/,
    message: '密码应为6～16位字母、数字或符号'
  }
}

export const form = {
  name: {
    label: '姓名',
    value: '',
    required: true,
    placeholder: '请填写您的姓名'
  },
  company: {
    label: '单位',
    value: '',
    required: true,
    placeholder: '请填写您的工作单位',
    list: []
  },
  initMobile: {
    label: '原手机号',
    value: '',
    required: true,
    placeholder: '请输入11位手机号',
    show: false,
    message: '请输入正确的11位手机号',
    reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  },
  newMobile: {
    label: '新手机号',
    value: '',
    required: true,
    placeholder: '请输入11位手机号',
    show: false,
    message: '请输入正确的11位手机号',
    reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  },
  verify: {
    label: '验证码',
    value: '',
    required: true,
    placeholder: '验证码',
    show: false,
    message: '请输入正确的6位验证码',
    reg: /^\d{6}$/
  },
  office: {
    label: '科室',
    value: '',
    required: true,
    placeholder: '请选择科室',
    list: getSelectList(office)
  },
  position: {
    label: '职称',
    value: '',
    required: true,
    placeholder: '请选择职称',
    list: getSelectList(position)
  },
  department: {
    label: '部门',
    value: '',
    required: true,
    placeholder: '请选择部门',
    list: getSelectList(department)
  },
  part: {
    label: '职位',
    value: '',
    required: true,
    placeholder: '请选择职位',
    list: getSelectList(part)
  },
  initPassword: {
    label: '原密码',
    value: '',
    required: true,
    placeholder: '请输入6-16位密码',
    show: false,
    reg: /^[a-z0-9]{6,16}$/,
    message: '密码应为6～16位字母、数字或符号'
  },
  password: {
    label: '新密码',
    value: '',
    required: true,
    placeholder: '请输入6-16位密码',
    show: false,
    reg: /^[a-z0-9]{6,16}$/,
    message: '密码应为6～16位字母、数字或符号'
  },
  ensure: {
    label: '确认密码',
    value: '',
    required: true,
    placeholder: '确认密码',
    show: false,
    reg: /^[a-z0-9]{6,16}$/,
    message: '密码应为6～16位字母、数字或符号'
  },
  mail: {
    label: '邮箱',
    value: '',
    required: false,
    placeholder: '请输入您的邮箱'
  }
}

export const titleMap = {
  0: '个人信息修改',
  1: '手机号码修改',
  2: '密码重置'
}