<template>
  <div class="right">
    <Divider type="vertical" />
    <template v-if="!show">
      <span class="code" @click="isCode">获取验证码</span>
    </template>
    <template v-else>
      <span class="code count">{{isUnit(count)}} s</span>
    </template>
  </div>
</template>

<script>
import { Divider } from 'view-design'
export default {
  name: 'CountDown',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 10
    },
    timer: {
      type: null
    }
  },

  components: {
    Divider
  },

  computed: {
    isUnit() {
      return function (code) {
        if (code <= 9 && code !== 0) {
          return `0${code}`
        } else {
          return code
        }
      }
    }
  },

  methods: {
    isCode() {
      this.$emit('code')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>