<template>
  <div class="form-item">
    <div class="label">
      <span v-if="form.required" class="dot">*</span>
      {{form.label}}
    </div>
    <div class="input-item" v-if="type==='input'">
      <!-- <template v-if="form.label === '密码' || form.label === '确认密码'">
        <Input v-model="form.value" :placeholder="form.placeholder" @on-blur="validate"/>
        <span v-if="form.show" class="error">{{form.message}}</span>
      </template> -->
      <template v-if="form.label === '验证码'">
        <div class="verify" :class="[isFocus && 'focus']">
          <Input v-model="form.value" :border="false" :placeholder="form.placeholder" @on-focus="isFocus = true" @on-blur="codeValidate"/>
          <div class="verify-code">
            <CountDown
              :show="show"
              :count="count"
              :timer="timer"
              :time="time"
              @code="isCode"
            />
          </div>
        </div>
        <span v-if="form.show" class="error">{{form.message}}</span>
      </template>
      <template v-else-if="form.label === '单位'">
        <Input v-model="form.value" :type="isType" :placeholder="form.placeholder" @on-change="handleChange" />
        <div class="company_list" v-if="form.list.length">
          <div class="list-item" v-for="item in form.list" :key="item.company_name" @click="handleItem(item)">{{item.company_name}}</div>
        </div>
      </template>
      <template v-else>
        <Input v-model="form.value" :type="isType" :password="isPassword" :placeholder="form.placeholder" @on-blur="validate"/>
        <span v-if="form.show" class="error">{{form.message}}</span>
      </template>
    </div>
    <div class="select-item" v-if="type==='select'">
      <Select v-model="form.value" :placeholder="form.placeholder">
        <Option v-for="item in form.list" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
    </div>
  </div>
</template>

<script>
import { Input, Select, Option } from 'view-design'
import CountDown from 'components/CountDown'
import { vueDebounce } from '@/utils/tools'
export default {
  name: 'FormItem',

  props: {
    form: {
      type: Object,
      default: () => ({
        label: '标题',
        value: '',
        required: true
      })
    },
    type: {
      type: String,
      default: 'input'
    },
    loading: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 10
    },
    timer: {
      type: null
    },
    time: {
      type: Number,
      default: 10
    }
  },

  data () {
    return {
      isFocus: false
    }
  },

  computed: {
    isType() {
      return this.isPassword ? 'password' : 'text'
    },
    isPassword() {
      // return (this.form.label === '密码' || this.form.label === '确认密码')
      return this.form.label.indexOf('密码') !== -1
    }
  },

  components: {
    Input,
    Select,
    Option,
    CountDown
  },

  methods: {
    validate() {
      this.$emit('blur')
    },
    codeValidate() {
      this.isFocus = false
      this.$emit('blur')
    },
    remoteMethod(query) {
      this.$emit('remote', query)
    },
    isChange(val) {
      this.$emit('change', val)
    },
    isDeounceChange: vueDebounce('isChange', 500),

    handleChange(e) {
      this.isDeounceChange(e.target.value)
    },
    handleQuery(query) {
      if (!query.trim()) {
        this.$emit('empty')
      }
    },
    handleItem(item) {
      this.$emit('company', item)
    },
    isCode() {
      this.$emit('code')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>